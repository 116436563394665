import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const ConstructionTechnologyFestival: React.FC = () => {
  return (
    <>
      <SEO
        title="Construction Technology Festival 2023 DUBAI"
        description="Join us at the 2023 Construction Technology Festival! Celebrate the latest advancements and trends in the construction industry. Engage with experts, explore cutting-edge solutions."
      />
      <NewsSectionBlock date="08.06.2023">
        <Typography variant="h1" color="blue">
          {`SIGNAX presented digital construction\n processes at ctf 2023 DUBAI`}
        </Typography>
        <Typography variant="body1">
          Annual Construction Technology Festival took place in Conrad hotel,
          Dubai, May, 31- June, 1.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/2023-06-08-construction-technology-festival/image-1.jpg"
            alt="Construction Technology Festival"
            title="Construction Technology Festival"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          As usual CTF is one of the biggest industrial conferences devoted to
          digital technologies in construction. It attracted over 600 attendees,
          featuring more than 150 esteemed speakers, and hosting 30+ sponsors
          and exhibitors this year. The main topics were around sustainability;
          opportunities to decrease of human factor at site; digital
          transformation of corporate processes with ERP/PMIS integrations;
          digital twin technologies.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/2023-06-08-construction-technology-festival/image-2.jpg"
            alt="Construction Technology Festival 2"
            title="Construction Technology Festival 2"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          SIGNAX presented how to ensure proper data management across key
          construction processes such as design & model checking, budget & work
          scope calculations, subcontractor supervision, and how remote project
          control will help organizations make sense of that digital data for
          competitive advantage.
        </Typography>
        <Typography variant="body1">
          The session dealed with 3 key issues:
        </Typography>
        <ol className={`${s.textLists} ${s.decimal}`}>
          <li>What digital data is & how it is being collected;</li>
          <li>
            How it can cover processes and bring radical value to the
            construction site;
          </li>
          <li>
            How the unified information center can be created and available for
            all the project participants.
          </li>
        </ol>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/2023-06-08-construction-technology-festival/preview.jpg"
            alt="Construction Technology Festival 3"
            title="Construction Technology Festival 3"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          SIGNAX stand attracted a big number of innovators from leading
          companies of the region. Many visitors asked how BIM model can be used
          on construction site and found answers in SIGNAX solutions presented
          there.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/2023-06-08-construction-technology-festival/image-4.jpg"
            alt="Construction Technology Festival 4"
            title="Construction Technology Festival 4"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Petr Manin, Regional director of SIGNAX, acted as an expert and judge
          of the annual Awards ceremony. More than 20 categories for both:
          companies and individual leaders took place this year at the contest.
          SIGNAX had the honor to congratulate Roshn group, the winner of
          «BIM\u00A0organization\u00A0of\u00A0the\u00A0year» award.
        </Typography>
        <Typography variant="body1">
          It is a great pleasure for us to participate and play the important
          role in the development of technologies in UAE, Saudi Arabia and whole
          MENA region.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/2023-06-08-construction-technology-festival/image-5.jpg"
            alt="Construction Technology Festival 5"
            title="Construction Technology Festival 5"
            placeholder="blurred"
          />
        </div>
      </NewsSectionBlock>
    </>
  )
}

export default ConstructionTechnologyFestival
